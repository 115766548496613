import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from 'moment';

import { Button } from "../../../../components/Wrappers/Wrappers";

import { PDFExport } from '@progress/kendo-react-pdf';
import Invoice from "./InvoicePageDesign";
import EditInvoice from "./EditInvoicePage";

export default function ViewJobDialog(props) {

  const pdfExportComponent = React.useRef(null);
  const pdfExportComponent2 = React.useRef(null);


  let tz = new Date().getTimezoneOffset();

  let {
    classes,
    jobCreateModal,
    handleClose,
    viewRowData,
    imageUrl,
    jobMaterialDetails,
    handelCreateApi,
    propsData,
  } = props;

  const getFormatedDate = (date) => {
    var dateString = new Date(date).toISOString().split("T")[0];
    return dateString;
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const thTdStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  const thStyle = {
    ...thTdStyle,
    backgroundColor: '#f2f2f2',
  };






  const getAddress = () => {
    return ` ${viewRowData.location_id && viewRowData.location_id.line1
      ? viewRowData.location_id.line1
      : "-"
      }
    ${viewRowData.location_id && viewRowData.location_id.line2
        ? viewRowData.location_id.line2
        : "-"
      }
    ${viewRowData.location_id && viewRowData.location_id.line3
        ? viewRowData.location_id.line3
        : "-"
      }`;
  };
  const generateAddress = () => {
    let address = "";
    if (viewRowData.location_id) {
      const { line1, line2, line3, city, state, pin } = viewRowData.location_id;
      address = `${line1} ${line2} ${line3} ${city} ${state} ${pin}`;
    }
    return address;
  };


  const getFormattedDate = (dateInMilliseconds) => {
    if (!dateInMilliseconds) {
      return " - ";
    }
    const date = new Date(dateInMilliseconds);
    return date.toDateString() + " " + date.toLocaleTimeString();
  };
  const getCompletionDate = () => {
    return getFormattedDate(viewRowData.completion_date);
  };
  const getJobStartDate = () => {
    return getFormattedDate(viewRowData.start_date);
  };

  const [editInvoice, setEditInvoice] = useState(false)

  function getMaterialForPerticularUserById(id) {
    return jobMaterialDetails.MaterialUsed.find((ele) => String(ele.userId.id) === String(id))
  }

  return (

    <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Job Details
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      {/* <Container component="main" maxWidth="xs">
        <CssBaseline /> */}
      {viewRowData.status === 2 && (
        <Grid container>
          <Grid item sm />
          <Grid item style={{ marginRight: "3.15%", marginTop: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={`${classes.submit}`}
              onClick={() => {
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
              Download Report
            </Button>
          </Grid>
          <Grid item style={{ marginRight: "3.15%", marginTop: "10px" }}>
            {viewRowData.assinged_IS_m && viewRowData.assinged_IS_m.length > 0 && (<Button
              // fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={`${classes.submit}`}
              onClick={() => setEditInvoice(!editInvoice)}
            >
              {editInvoice ? "Close" : "Edit"} Invoice
            </Button>)}
          </Grid>
          {jobMaterialDetails && jobMaterialDetails.invoiceDetailsByJobId && <Grid item style={{ marginRight: "3.15%", marginTop: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={`${classes.submit}`}
              onClick={() => {
                if (pdfExportComponent2.current) {
                  pdfExportComponent2.current.save();
                }
              }}
            >
              Download Invoice
            </Button>
          </Grid>}
        </Grid>
      )}

      {!editInvoice ? <>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            <u>Job Details</u>
          </Typography>
        </div>
        <div className={classes.jobPaper}>
          <Typography style={{ fontSize: "17px" }} component="h1" variant="h5">
            <Box
              className={classes.jobText}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              <b>Job Id : </b>
              {viewRowData.job_id}
            </Box>
            <Box
              className={classes.jobText}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              <b>Job Title : </b>
              {viewRowData.job_title}
            </Box>
            <Box
              className={classes.jobText}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              <b>Job Details :</b> {viewRowData.job_details}
            </Box>
            <Box
              className={classes.jobText}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              <b>Assigned By :</b> {viewRowData && viewRowData.assingnedName ? viewRowData.assingnedName : "N/A"}
            </Box>
            <br />
            <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <b>Address :</b>
            </Box>
            <Box
              style={{
                marginLeft: "40px",
                marginTop: "5px",
                fontSize: "16px",
              }}
            >
              <Box className={classes.jobText}>
                <b>Street Number/Name : </b>
                {getAddress()}
              </Box>
              <Box className={classes.jobText}>
                <b> Postal/Zip Code :</b>
                {viewRowData.location_id && viewRowData.location_id.pin
                  ? viewRowData.location_id.pin
                  : "-"}
              </Box>
              <Box className={classes.jobText}>
                <b>City : </b>
                {viewRowData.location_id && viewRowData.location_id.city
                  ? viewRowData.location_id.city
                  : "-"}
              </Box>
              <Box className={classes.jobText}>
                <b>Province/State : </b>
                {viewRowData.location_id && viewRowData.location_id.state
                  ? viewRowData.location_id.state
                  : "-"}
              </Box>
              <Box className={classes.jobText}>
                <b>Country : </b>
                {viewRowData.location_id && viewRowData.location_id.country
                  ? viewRowData.location_id.country
                  : "-"}
              </Box>
            </Box>
            <br />
            <br />
            {viewRowData.assign_to && (
              <>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Contractor :</b>
                </Box>
                <Box style={{ marginLeft: "40px", fontSize: "16px" }}>
                  <Box className={classes.jobText}>
                    <b>Company Name : </b>
                    {viewRowData.assign_to && viewRowData.assign_to.company_name
                      ? viewRowData.assign_to.company_name
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Contact Name : </b>
                    {viewRowData && viewRowData.assign_to && viewRowData.assign_to.contact_name
                      ? viewRowData.assign_to.contact_name
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Contact Email : </b>
                    {viewRowData.assign_to && viewRowData.assign_to.email
                      ? viewRowData.assign_to.email
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Contact Number : </b>
                    {viewRowData.assign_to && viewRowData.assign_to.contact
                      ? viewRowData.assign_to.contact
                      : "-"}
                  </Box>
                </Box>{" "}
                <br />
              </>
            )}

            {viewRowData &&
              viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.map((worker, index) => {
                return (
                  <>
                    <br />
                    <Box
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <b>Worker {index + 1}:</b>
                    </Box>{" "}
                    <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                      <Box className={classes.jobText}>
                        <b>Company Name : </b>{" "}
                        {viewRowData && viewRowData.assign_to && viewRowData.assign_to.company_name
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Worker Name : </b>{" "}
                        {worker && worker.workerId
                          ? worker.workerId.first_name + " " + worker.workerId.last_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact No. : </b>{" "}
                        {worker && worker.workerId && worker.workerId.contact ? worker.workerId.contact : "-"}
                      </Box>
                    </Box>
                  </>
                );
              })}

            <br />
            {
              viewRowData && viewRowData.jobType_Id && viewRowData.jobType_Id.name == "PM" ? <>
                <Box
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: viewRowData.jobType_Id ? (viewRowData.jobType_Id.name == "PM" ? "block" : "none") : "none",
                  }}
                >
                  <b>PM Dates: </b>
                </Box>
                {viewRowData.jobType_PM && viewRowData.jobType_PM.map((date, i) => {
                  return (<React.Fragment >
                    <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                      <Box className={classes.jobText}>
                        <b>Date {i + 1}:</b>{" "}
                        {date && date ? getFormatedDate(date) : "-"}
                      </Box>
                    </Box>
                  </React.Fragment>
                  );
                })}</> : null
            }

            {viewRowData.assinged_IS_m && viewRowData.assinged_IS_m.map((ele, index) => {
              return <React.Fragment>
                <Box style={{ textOverflow: "ellipsis" }} key={index}>
                  <b>Internal Staff {index + 1}:</b>
                  <Box style={{ marginLeft: "20px", fontSize: "16px" }} >
                    <Box className="pl-3 fw-normal">
                      <b> Contact Name : </b>
                      {ele && ele.first_name ? ele.first_name + " " + ele.last_name : "-"}
                    </Box>
                    <Box className="pl-3 fw-normal">
                      <b> Contact Email : </b>
                      {ele && ele.email ? ele.email : "-"}
                    </Box>
                    <Box className="pl-3 fw-normal">
                      <b> Contact No. : </b>
                      {ele && ele.contact ? ele.contact : "-"}
                    </Box>
                    <br />
                  </Box>
                </Box>
              </React.Fragment>
            })}
          </Typography>
        </div>
        {viewRowData.status === 2 && (

          <PDFExport
            keepTogether="p" paperSize="A4"
            scale={0.75}
            ref={pdfExportComponent}
          >


            <div className={classes.serviceReportContainer}>
              <div
                style={{
                  padding: "25px",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: "30px", border: "5px solid #2E74B5" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "baseline",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src="./Logo V2.jpg"
                      alt="logo"
                      className={classes.reportLogo}
                    />
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "150px",
                      }}
                    >
                      <u>SERVICE REPORT</u>
                    </h2>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={classes.itemsContainer}>
                      <span>COMPANY NAME :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData.created_by.company_name}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>ASSIGNED BY :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData && viewRowData.assingnedName ? viewRowData.assingnedName : "N/A"}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB TITLE :</span>

                      <span className={classes.itemDetails}>
                        {viewRowData.job_title
                          ? viewRowData.job_title
                          : viewRowData.job_title}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB DETAILS :</span>

                      <span className={classes.itemDetails}>
                        {viewRowData.job_details
                          ? viewRowData.job_details
                          : viewRowData.job_details}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB ID :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData.job_id ? viewRowData.job_id : "-"}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>LOCATION :</span>
                      <span className={classes.itemDetails}>
                        {generateAddress()}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>DATE CREATED :</span>
                      <span className={classes.itemDetails}>
                        {moment(new Date(parseInt(viewRowData.createdAt))).utc(tz).format("MMMM Do YYYY ")}
                      </span>
                    </div>

                    <div className={classes.itemsContainer}>
                      <span>CONTRACTOR :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData && viewRowData.assign_to
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>INTERNAL STAFF :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData && viewRowData.assinged_IS
                          ? viewRowData.assinged_IS.email
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className={(classes.itemsContainer, classes.itemsContainerDate)}
                      >
                        <span>JOB START DATE :</span>
                        <span className={classes.itemDetails}>
                          {getJobStartDate()}
                        </span>
                      </div>
                      <div
                        className={
                          (classes.itemsContainer, classes.itemsContainerDate)
                        }
                      >
                        <span>DATE OF COMPLETION :</span>
                        <span className={classes.itemDetails}>
                          {getCompletionDate()}
                        </span>
                      </div>
                    </div>
                    {viewRowData && viewRowData.assigned_internal_staff_status_m && viewRowData.assigned_internal_staff_status_m.length > 0 ? viewRowData.assigned_internal_staff_status_m.map((element, index) => <React.Fragment>
                      {element.staffStatus == 2 ? <>
                        <div className={classes.itemLayoutOuter}>

                          <div className={classes.itemsContainer}>
                            <span>EMPLOYEE :</span>
                            <span className={classes.itemDetails}>
                              {element.staffId.first_name + " " + element.staffId.last_name}

                            </span>
                          </div>
                          <div className={classes.itemLayout}>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >

                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>ON-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                  element.timeData.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                  element.timeData.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                            </div>
                          </div>
                          <div className={classes.itemLayout}>


                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >
                                <span>TOTAL ON-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                  element.onOffSite.finalOnTime : "-"} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>TOTAL OFF-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                  element.onOffSite.finalOffTime : "-"} </span>
                              </div>
                            </div>
                          </div>

                          <div className={classes.itemLayout}>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >

                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-NETWORK START TIME :</span>
                                <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                  element.delayDuration.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-NETWORK END TIME :</span>
                                <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                  element.delayDuration.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                            </div>
                          </div>

                          <div
                            className={classes.itemsContainer}
                            style={{ marginTop: "30px" }}
                          >
                            <span>DESCRIPTION OF WORK : </span>
                            <span className={classes.itemDetails}>
                              {element && element.staffWorkDetails ? element.staffWorkDetails : "-"}
                            </span>
                          </div>

                          {getMaterialForPerticularUserById(element?.staffId?.id) &&
                            <div>
                              {getMaterialForPerticularUserById(element?.staffId?.id).materials.length > 0 && (<div className={classes.itemsContainer}
                                style={{ marginTop: "30px" }}>
                                <span>MATERIAL USED: </span>
                                <div>
                                  <table style={tableStyle}>
                                    <thead >
                                      <tr >
                                        <th style={thStyle} >Quantity</th>
                                        <th style={{ ...thStyle, whiteSpace: 'nowrap' }} >Material Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {getMaterialForPerticularUserById(element?.staffId?.id).materials && getMaterialForPerticularUserById(element?.staffId?.id).materials.length > 0 && getMaterialForPerticularUserById(element?.staffId?.id).materials?.map((ele, index) =>
                                        <tr
                                          key={index}
                                        >
                                          <td style={thTdStyle}>{ele.qty}</td>
                                          <td style={thTdStyle}>{ele.materialDescription}</td>
                                        </tr>)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>)}

                              {getMaterialForPerticularUserById(element?.staffId?.id).materials.length > 0 && (<div className={classes.itemsContainer}
                                style={{ marginTop: "30px" }}>
                                <span>EQUIPMENT USED: </span>
                                <div>
                                  <table style={tableStyle}>
                                    <thead >
                                      <tr >
                                        <th style={thStyle} >Quantity</th>
                                        <th style={{ ...thStyle, whiteSpace: 'nowrap' }} >Equipment Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {getMaterialForPerticularUserById(element?.staffId?.id).equipment && getMaterialForPerticularUserById(element?.staffId?.id).equipment.length > 0 && getMaterialForPerticularUserById(element?.staffId?.id).equipment?.map((ele, index) =>
                                        <tr
                                          key={index}
                                        >
                                          <td style={thTdStyle}>{ele.qty}</td>
                                          <td style={thTdStyle}>{ele.equipmentDescription}</td>
                                        </tr>)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>)}
                            </div>
                          }

                          <div
                            className={classes.itemsContainer}
                            style={{ marginTop: "30px" }}
                          >
                            <span>
                              <u>ATTACHED MEDIA :</u>
                              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                {
                                  element && element.image && element.image.length > 0 ?
                                    element.image.map((elmentdd) => (<>
                                      <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                    </>
                                    ))
                                    : null
                                }
                              </div>
                            </span>
                          </div>
                        </div>
                        <br />
                      </> : null} </React.Fragment>) : viewRowData && viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.length > 0 ? viewRowData.assigned_worker_status.map((element) => <React.Fragment>
                        {element.workerStatus == 2 ? <>
                          <div className={classes.itemLayoutOuter}>

                            <div className={classes.itemsContainer}>
                              <span>EMPLOYEE :</span>
                              <span className={classes.itemDetails}>
                                {element.workerId.first_name + " " + element.workerId.last_name}

                              </span>
                            </div>
                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}

                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>
                            <div className={classes.itemLayout}>


                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >
                                  <span>TOTAL ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOnTime : "-"} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>TOTAL OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOffTime : "-"} </span>
                                </div>
                              </div>
                            </div>

                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK START TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK END TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>DESCRIPTION OF WORK : </span>
                              <span className={classes.itemDetails}>
                                {element && element.workerWorkDetails ? element.workerWorkDetails : "-"}
                              </span>
                            </div>

                            {getMaterialForPerticularUserById(element.workerId.id) &&
                              <div>
                                {getMaterialForPerticularUserById(element.workerId.id).materials.length > 0 && (<div className={classes.itemsContainer}
                                  style={{ marginTop: "30px" }}>
                                  <span>MATERIAL USED: </span>
                                  <div>
                                    <table style={tableStyle}>
                                      <thead >
                                        <tr >
                                          <th style={thStyle} >Quantity</th>
                                          <th style={{ ...thStyle, whiteSpace: 'nowrap' }} >Material Description</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {getMaterialForPerticularUserById(element.workerId.id).materials && getMaterialForPerticularUserById(element.workerId.id).materials.length > 0 && getMaterialForPerticularUserById(element.workerId.id).materials?.map((ele, index) =>
                                          <tr
                                            key={index}
                                          >
                                            <td style={thTdStyle}>{ele.qty}</td>
                                            <td style={thTdStyle}>{ele.materialDescription}</td>
                                          </tr>)}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>)}

                                {getMaterialForPerticularUserById(element.workerId.id).materials.length > 0 && (<div className={classes.itemsContainer}
                                  style={{ marginTop: "30px" }}>
                                  <span>EQUIPMENT USED: </span>
                                  <div>
                                    <table style={tableStyle}>
                                      <thead >
                                        <tr >
                                          <th style={thStyle} >Quantity</th>
                                          <th style={{ ...thStyle, whiteSpace: 'nowrap' }} >Equipment Description</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {getMaterialForPerticularUserById(element.workerId.id).equipment && getMaterialForPerticularUserById(element.workerId.id).equipment.length > 0 && getMaterialForPerticularUserById(element.workerId.id).equipment?.map((ele, index) =>
                                          <tr
                                            key={index}
                                          >
                                            <td style={thTdStyle}>{ele.qty}</td>
                                            <td style={thTdStyle}>{ele.equipmentDescription}</td>
                                          </tr>)}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>)}
                              </div>
                            }

                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>
                                <u>ATTACHED MEDIA :</u>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                  {
                                    element && element.image && element.image.length > 0 ?
                                      element.image.map((elmentdd) => (<>
                                        <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                      </>
                                      ))
                                      : null
                                  }
                                </div>
                              </span>
                            </div>



                          </div>
                          <br />
                        </> : null}
                      </React.Fragment>) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
        )}
      </> : jobMaterialDetails && jobMaterialDetails.MaterialUsed && jobMaterialDetails.MaterialUsed.length > 0 && <EditInvoice
        jobMaterialDetails={jobMaterialDetails}
        viewRowData={viewRowData}
        setEditInvoice={setEditInvoice}
        handelCreateApi={(details) => handelCreateApi(details)}
        propsData={propsData}
      />}

      <div style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        <PDFExport
          keepTogether="p"
          paperSize="A4"
          scale={0.75}
          ref={pdfExportComponent2}
        >
          {jobMaterialDetails && jobMaterialDetails.MaterialUsed && jobMaterialDetails.MaterialUsed.length > 0 && <Invoice
            jobMaterialDetails={jobMaterialDetails}
            viewRowData={viewRowData}
          />}
        </PDFExport>
      </div>
    </Dialog>
  );
}
