import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const staffService = {
  login,
  logout,
  addStaff,
  updatePassword,
  uploadImage,
  getStaffList,
  // getContractorByLandord,
  getIsList,
  disableStaff,
  updateStaff,
  deleteStaff,
  getAllStaff,
  getInternalStaffByLandLord,
  getInternalStaffLandLordList,
  addStaffLandlord,
  updateStaffLandlord,
  disableStaffLandlord,
  updatePasswordLandlord,
  getInternalStaffByJob
};
function logout() {
  localStorage.removeItem("staff");
}
function login(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
    .then(handleResponse)
    .then((staff) => {
      let staffObj = {
        staffinfo: staff.data,
      };
      if (staff.data) {
        localStorage.setItem("staff", JSON.stringify(staff.data));
      }

      return staffObj;
    });
}
function getInternalStaffLandLordList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  console.log("@@@@@@@@@@@@@@@@@10101:", header.Authorization);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL2 + `/getInternalStaffByLandLord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getInternalStaffLandLordList: data.data,
      };
      console.log("I am in service getInternalStaffLandLordList:::", staffObj);

      return staffObj;
    });
}

// function getContractorByLandord(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   console.log("@@@@@@@@@@@@@@@@@10101:", header.Authorization);

//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
//   return fetch(CONST.BACKEND_URL2 + `/getContractorByLandord`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let staffObj = {
//         getInternalStaffByLandLord: data.data,
//       };
//       console.log("I am in service getInternalStaffByLandLord:::", staffObj);

//       return staffObj;
//     });
// }
function getInternalStaffByLandLord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  console.log("@@@@@@@@@@@@@@@@@10101:", header.Authorization);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL2 + `/getInternalStaffByLandLord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getInternalStaffByLandLord: data.data,
      };
      console.log("I am in service getInternalStaffByLandLord:::", staffObj);

      return staffObj;
    });
}
function getInternalStaffByJob(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  // console.log("@@@@@@@@@@@@@@@@@10101:", header.Authorization);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL2 + `/getInternalStaffByLandLord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getInternalStaffByLandLord: data.data,
      };
      console.log("I am in service getInternalStaffByLandLord:::", staffObj);

      return staffObj;
    });
}
function getStaffList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getIsByAdmin`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getStaffList: data.data,
      };
      console.log();

      return staffObj;
    });
}
function getIsList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getIsByAdmin`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getIsList: data.data,
      };
      console.log();

      return staffObj;
    });
}
function getAllStaff() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
  };
  return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        getAllStaff: data.data,
      };
      console.log();

      return staffObj;
    });
}

function updatePasswordLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/updatePasswordInternalStaffLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let userObj = {
        updatePasswordLandlord: data.data,
      };
      console.log("I am in service", userObj);

      return userObj;
    });
}

function updatePassword(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updatePassword`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let userObj = {
        updatePassword: data.data,
      };
      console.log("I am in service", userObj);

      return userObj;
    });
}

function addStaffLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL2 + `/createNewUserISNew`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        addStaffLandlord: data.data,
      };
      console.log();

      return staffObj;
    });
}

function addStaff(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL + `/createNewUserISNew`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        addStaff: data.data,
      };
      console.log();

      return staffObj;
    });
}
function updateStaffLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/updateInternalStaffLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        updateStaffLandlord: data.data,
      };
      return staffObj;
    });
}
function updateStaff(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        addStaff: data.data,
      };
      return staffObj;
    });
}
function disableStaffLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/changeStateLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        disableStaffLandlord: data.data,
      };
      return staffObj;
    });
}
function disableStaff(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        addStaff: data.data,
      };
      return staffObj;
    });
}
function deleteStaff(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/deleteStaff`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let staffObj = {
        addStaff: data.data,
      };
      console.log();

      return staffObj;
    });
}
function uploadImage(filedata) {
  let header = new Headers({
    Authorization: authHeader().Authorization,
  });
  var data = new FormData();
  data.append("image", filedata);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: data,
  };
  return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      let staffObj = {
        filesDetails: res.data,
      };
      return staffObj;
    });
}
function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
