import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "../../../../components/Wrappers/Wrappers";
import floorPlan from "../../../../images/floor-plan.svg";
import roofPlan from "../../../../images/roof-plan.svg";
import Fire from "../../../../images/fire.svg";
import Electrical from "../../../../images/electrical.svg";
import Sprinkler from "../../../../images/sprinkler.svg";
import Boiler from "../../../../images/boiler.svg";
import Plumbing from "../../../../images/plumber-_1_ (1).svg";
import Lift from "../../../../images/lift-elevator-icon (1).svg"
import HVAC from "../../../../images/hvac.svg";
import ViewJobPdfDetails from "../ViewJobPdfDetails/ViewJobPdfDetails";
import moment from 'moment';


export default function ViewJobDialog(props) {

  let tz = new Date().getTimezoneOffset();;
  let {
    classes,
    jobCreateModal,
    siteInfoData,
    handleClose,
    viewRowData,
  } = props;


  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentName, setViewDocumentName] = useState("");
  const [viewDocumentLink, setViewDocumentLink] = useState([]);
  const handleClosePdf = () => {
    setViewDocument(false);
  };



  const getAddress = () => {
    return `${viewRowData.location_id && viewRowData.location_id.city
      ? viewRowData.location_id.city
      : "-"
      } ${viewRowData.location_id && viewRowData.location_id.line1
        ? viewRowData.location_id.line1
        : "-"
      } ${viewRowData.location_id && viewRowData.location_id.line2
        ? viewRowData.location_id.line2
        : "-"
      } ${viewRowData.location_id && viewRowData.location_id.line3
        ? viewRowData.location_id.line3
        : "-"
      }`;
  };




  const getFormatedDate = (date) => {
    // const dates = new Date(date).toLocaleString();
    // var options = { year: "numeric", month: "numeric", day: "numeric" };
    // return new Date(date).toLocaleDateString([], options);
    var dateString = new Date(date).toISOString().split("T")[0];
    return dateString;
  };


  return (
    <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Job Details
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "0 30px",
          }}
        >
          <div className={classes.paper} style={{ maxWidth: "50%" }}>
            <Typography component="h1" variant="h5">
              <u>Job Details</u>
            </Typography>

            <div className={classes.paper}>
              <Typography
                style={{ fontSize: "17px" }}
                component="h1"
                variant="h5"
              >
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Job Title : </b>
                  {viewRowData.job_title}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Job Description :  </b> {viewRowData.job_details}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Job ID : </b> {viewRowData.job_id}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Created Date : </b>{moment(new Date(parseInt(viewRowData.createdAt))).utc(tz).format("YYYY-MM-DD hh:mm A")}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Assigned By : </b>{viewRowData && viewRowData.assingnedName ? viewRowData.assingnedName : "N/A"}
                </Box>
                <br />
                {
                  viewRowData && viewRowData.jobType_Id
                    && viewRowData.jobType_Id.name === "PM" ? <>
                    <Box
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: viewRowData.jobType_Id
                          ? viewRowData.jobType_Id.name === "PM"
                            ? "block"
                            : "none"
                          : "none",
                      }}
                    >
                      <b> Date Scheduled PM: </b>
                    </Box>
                    {viewRowData.jobType_PM &&
                      viewRowData.jobType_PM.map((date, index) => {
                        return (
                          <>
                            <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                              <Box className={classes.jobText}>
                                <b>Date {index + 1}:</b>{" "}
                                {date && date ? getFormatedDate(date) : "-"}
                              </Box>
                            </Box>
                          </>
                        );
                      })}</> : null

                }
                <br />
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Address : </b>
                </Box>
                <Box
                  style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    fontSize: "16px",
                  }}
                >
                  <Box className={classes.jobText}>
                    <b>Street Number/Name : </b>
                    {getAddress()}
                  </Box>
                  <Box className={classes.jobText}>
                    <b> Postal/Zip Code : </b>
                    {viewRowData.location_id && viewRowData.location_id.pin
                      ? viewRowData.location_id.pin
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>City : </b>
                    {viewRowData.location_id && viewRowData.location_id.city
                      ? viewRowData.location_id.city
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Province/State : </b>
                    {viewRowData.location_id && viewRowData.location_id.state
                      ? viewRowData.location_id.state
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Country : </b>
                    {viewRowData.location_id && viewRowData.location_id.country
                      ? viewRowData.location_id.country
                      : "-"}
                  </Box>
                </Box>

                <br />
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Landlord :</b>
                </Box>
                <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                  <Box className="pl-3 fw-normal">
                    <b>Company Name : </b>
                    {viewRowData.created_by &&
                      viewRowData.created_by.company_name
                      ? viewRowData.created_by.company_name
                      : "-"}
                  </Box>
                  <Box className="pl-3 fw-normal">
                    <b>Email : </b>
                    {viewRowData.created_by && viewRowData.created_by.email
                      ? viewRowData.created_by.email
                      : "-"}
                  </Box>
                  <Box className="pl-3 fw-normal">
                    <b>Contact No.: </b>
                    {viewRowData.created_by && viewRowData.created_by.contact
                      ? viewRowData.created_by.contact
                      : "-"}
                  </Box>

                </Box>

                {viewRowData.assign_to && (
                  <>
                    <br />
                    <Box
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                      <b>Contractor :</b>
                    </Box>
                    <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                      <Box className={classes.jobText}>
                        <b>Company Name : </b>
                        {viewRowData.assign_to &&
                          viewRowData.assign_to.company_name
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Name : </b>
                        {viewRowData.assign_to &&
                          viewRowData.assign_to.contact_name
                          ? viewRowData.assign_to.contact_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Email : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.email
                          ? viewRowData.assign_to.email
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Number : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.contact
                          ? viewRowData.assign_to.contact
                          : "-"}
                      </Box>
                    </Box>
                  </>
                )}


                <>
                  <br />
                  <Box
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    <b>Internal Staff :</b>
                  </Box>
                  {viewRowData && viewRowData.assigned_internal_staff_status_m && viewRowData.assigned_internal_staff_status_m.length > 0 ?
                    viewRowData.assigned_internal_staff_status_m.map((worker, index) => (
                      <Box className="py-4" style={{ marginLeft: "20px", fontSize: "16px" }}>
                        <Box className="pl-3 fw-normal">
                          <b> Contact Name : </b>
                          {worker && worker.staffId &&
                            worker.staffId.first_name
                            ? worker.staffId.first_name + " " + worker.staffId.last_name
                            : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact Email : </b>
                          {worker && worker.staffId &&
                            worker.staffId.email
                            ? worker.staffId.email
                            : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact No. : </b>
                          {worker && worker.staffId &&
                            worker.staffId.contact
                            ? worker.staffId.contact
                            : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Status : </b>
                          {worker && worker.jobStartTime && worker.jobStartTime !== 0 ? "JOB Started"
                            : "Not Started"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Job Start Date : </b>
                          {worker && worker.jobStartTime && worker.jobStartTime !== 0 ?
                            // moment(new Date(parseInt(worker.timeData[0].onSiteTime))).utc(tz).format("YYYY-MM-DD hh:mm A")
                            new Date(worker.jobStartTime * 1000).toLocaleString()
                            : "-"}
                        </Box>
                        <br />
                      </Box>
                    )) : "Internal staff  not assigned"}
                </>

                {viewRowData && viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.map((worker, index) => {
                  return (
                    <>
                      <br />
                      <Box
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <b>Worker {index + 1}:</b>
                      </Box>{" "}
                      <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                        <Box className={classes.jobText}>
                          <b>Company Name : </b>{" "}
                          {viewRowData && viewRowData.assign_to && viewRowData.assign_to.company_name
                            ? viewRowData.assign_to.company_name
                            : "-"}
                        </Box>
                        <Box className={classes.jobText}>
                          <b>Worker Name : </b>{" "}
                          {worker && worker.workerId &&
                            worker.workerId.first_name
                            ? worker.workerId.first_name + " " + worker.workerId.last_name
                            : "-"}

                          {/* {worker
                              ? worker.first_name + " " + worker.last_name
                              : "-"} */}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact Email : </b>

                          {worker && worker.workerId && worker.workerId.email
                            ? worker.workerId.email
                            : "-"}

                          {/* {worker && worker.email
                            ? worker.email
                            : "-"} */}
                        </Box>
                        <Box className={classes.jobText}>
                          <b>Contact No : </b>{" "}
                          {worker && worker.workerId && worker.workerId.contact ?
                            worker.workerId.contact : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Status : </b>
                          {worker && worker.jobStartTime && worker.jobStartTime !== 0 ? "JOB Started"
                            : "Not Started"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Job Start Date : </b>
                          {worker && worker.jobStartTime && worker.jobStartTime !== 0 ?
                            // moment(new Date(parseInt(worker.timeData[0].onSiteTime))).utc(tz).format("YYYY-MM-DD hh:mm A")
                            new Date(worker.jobStartTime * 1000).toLocaleString()
                            : "-"}
                        </Box>
                      </Box>
                    </>
                  );
                })}
                <br />
                <br />
                <br />
              </Typography>
            </div>
          </div>



          <div
            style={{
              backgroundColor: "#f6f7f8",
              minHeight: "100vh",
              padding: "100px 30px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.floorPlansFileLinks ?
                    siteInfoData.floorPlansFileLinks : []);
                  setViewDocumentName(siteInfoData.floorPlans)
                }}
              >
                <img src={floorPlan} width="183px" height="" alt=" " />
              </button>
              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.roofPlansFileLinks ?
                    siteInfoData.roofPlansFileLinks : []);

                  setViewDocumentName(siteInfoData.roofPlans)
                }}
              >
                <img src={roofPlan} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.fireCertificationFileLinks ?
                    siteInfoData.fireCertificationFileLinks : []);
                  setViewDocumentName(siteInfoData.fireCertification)
                }}
              >
                <img src={Fire} width="183px" alt=" " />
              </button>
            </div>
            <div>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.electricalRoomFileLinks ?
                    siteInfoData.electricalRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.electricalRoom)
                }}
              >
                <img src={Electrical} width="183px" height="" alt=" " />
              </button>

              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.sprinklerRoomFileLinks ?
                    siteInfoData.sprinklerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.sprinklerRoom)
                }}
              >
                <img src={Sprinkler} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.boilerRoomFileLinks ?
                    siteInfoData.boilerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.boilerRoom)
                }}
              >
                <img src={Boiler} width="183px" alt=" " />
              </button>
            </div>
            <div>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.hvacEquipmentFileLinks ?
                    siteInfoData.hvacEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.hvacEquipment)
                }}
              >
                <img src={HVAC} width="183px" height="" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.plumbingEquipmentFileLinks ?
                    siteInfoData.plumbingEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.plumbingEquipment)
                }}
              >
                <img src={Plumbing} width="183px" height="" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.liftEquipmentFileLinks ?
                    siteInfoData.liftEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.liftEquipment)
                }}
              >
                <img src={Lift} width="183px" height="" alt=" " />
              </button>
            </div>
            <div>

            </div>
          </div>



        </div>
      </div>
      <ViewJobPdfDetails
        show={viewDocument}
        viewDocumentName={viewDocumentName}
        viewDocumentLink={viewDocumentLink}

        handleClosePdf={handleClosePdf} />
    </Dialog>
  );
}
