export const jobConstants = {
    LOGIN_REQUEST: 'JOBS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'JOBS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'JOBS_LOGIN_FAILURE',

    LOGOUT: 'JOBS_LOGOUT',

    GETALL_REQUEST: 'JOBS_GETALL_REQUEST',
    GETALL_SUCCESS: 'JOBS_GETALL_SUCCESS',
    GETALL_FAILURE: 'JOBS_GETALL_FAILURE',

    GET_ALL_JOB_REQUEST: 'JOBS_GET_ALL_JOB_REQUEST',
    GET_ALL_JOB_SUCCESS: 'JOBS_GET_ALL_JOB_SUCCESS',
    GET_ALL_JOB_FAILURE: 'JOBS_GET_ALL_JOB_FAILURE',
    
    GETALL_JOB_NOTIFY_REQUEST: 'GETALL_JOB_NOTIFY_REQUEST',
    GETALL_JOB_NOTIFY_SUCCESS: 'GETALL_JOB_NOTIFY_SUCCESS',
    GETALL_JOB_NOTIFY_FAILURE: 'GETALL_JOB_NOTIFY_FAILURE',

    UPDATE_JOB_NOTIFY_REQUEST: 'UPDATE_JOB_NOTIFY_REQUEST',
    UPDATE_JOB_NOTIFY_SUCCESS: 'UPDATE_JOB_NOTIFY_SUCCESS',
    UPDATE_JOB_NOTIFY_FAILURE: 'UPDATE_JOB_NOTIFY_FAILURE',


    ADD_RESTAURANT_JOB_REQUEST: 'ADD_RESTAURANT_JOB_REQUEST',
    ADD_RESTAURANT_JOB_SUCCESS: 'ADD_RESTAURANT_JOB_SUCCESS',
    ADD_RESTAURANT_JOB_FAILURE: 'ADD_RESTAURANT_JOB_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',


    MATERIAL_DETAILS_REQUEST: 'MATERIAL_DETAILS_REQUEST',
    MATERIAL_DETAILS_SUCCESS: 'MATERIAL_DETAILS_SUCCESS',
    MATERIAL_DETAILS_FAILURE: 'MATERIAL_DETAILS_FAILURE',


    UPDATE_MATERIAL_JOB_REQUEST: "UPDATE_MATERIAL_JOB_REQUEST",
    UPDATE_MATERIAL_JOB_SUCCESS: "UPDATE_MATERIAL_JOB_SUCCESS",
    UPDATE_MATERIAL_JOB_FAILURE: "UPDATE_MATERIAL_JOB_FAILURE",


    CREATE_INVOICE_JOB_REQUEST: "CREATE_INVOICE_JOB_REQUEST",
    CREATE_INVOICE_JOB_SUCCESS: "CREATE_INVOICE_JOB_SUCCESS",
    CREATE_INVOICE_JOB_FAILURE: "CREATE_INVOICE_JOB_FAILURE",

};
