import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
// import SelectField from 'material-ui/SelectField';

// // import SelectField from 'material-ui/SelectField';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import Select2 from 'react-select';


// import ListItemText from '@mui/material/ListItemText';

import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function DeleteWorkerDialog(props) {
  let {
    classes,
    workerDeleteCloseModal,
    onDeleteClick,
    viewDeleteData,
    assignWorkerForJobSubmit,
    deleteWorkerModal, offset
  } = props;
  console.log('viewDeleteDataviewDeleteDataviewDeleteData',viewDeleteData)
  
  return (
    <Dialog fullScreen open={deleteWorkerModal} onClose={workerDeleteCloseModal}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={workerDeleteCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Delete Internal Staff
          </Typography>
          <Button color="inherit" onClick={workerDeleteCloseModal}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
          Delete Internal Staff
          </Typography>



          <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <Table className="mb-0">
                  <TableHead style={{ background: "#f6f6f6" }}>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>S. No.</TableCell>
                      <TableCell style={{ width: "15%" }}>Name</TableCell>
                       {/* <TableCell style={{ width: "25%" }}>Address</TableCell> */}
                      <TableCell style={{ width: "25%" }}>Email</TableCell> 
                      <TableCell style={{ width: "15%" }}>Deletion</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      viewDeleteData && viewDeleteData.assigned_internal_staff_status_m && viewDeleteData.assigned_internal_staff_status_m.length > 0 ? viewDeleteData.assigned_internal_staff_status_m.map((items, index) => (
                        <TableRow
                        // key={index}
                        >
                          <TableCell className="pl-3 fw-normal">
                            {offset + index + 1}
                          </TableCell>


 
                          <TableCell className="pl-3 fw-normal">
                            {items && items.staffId && items.staffId.first_name
                    ? items.staffId.first_name
                    : "-"} {" "}
                            {items && items.staffId&& items.staffId.last_name
                    ? items.staffId.last_name
                    : "-"} 
                          </TableCell>
 
                          <TableCell className="pl-3 fw-normal">
                            {items && items.staffId && items.staffId.email
                    ? items.staffId.email
                    : "-"}
                          </TableCell>
                    
                          <TableCell style={{ textAlign: "left" }}>
                            <Button
                              color="primary"
                              size="small"
                              className="px-2"
                              variant="contained"
                              style={{ marginRight: "5px" }}
                              onClick={() => onDeleteClick(items,viewDeleteData)}
                            >
                               Delete Internal Staff
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) : "not found"
                    }

                    {/* ))} */}
                  </TableBody>
                </Table>
              </Table>
            </Paper>
            {/* <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button> */}
          </ValidatorForm>

        </div>
      </Container>
    </Dialog>
  );
}
