import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddLandlordDialog from "./components/AddLandlordDialog/AddLandlordDialog";
import UpdateLandlordDialog from "./components/UpdateLandlordDialog/UpdateLandlordDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import ViewLandlordDialog from "./components/ViewLandlordDialog/ViewLandlordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { landlordActions, locationLandlordActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
import AddLocationLandlordDialog from "./components/AddLocationLandlordDialog/AddLocationLandlordDialog";
const theme = createMuiTheme();

class LandlordManagement extends Component {
  constructor(props) {
    document.title = "ATS | Administrator";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleOpenLocationCreateModal =
      this.handleOpenLocationCreateModal.bind(this);
    this.handleCloseLocationCreateModal =
      this.handleCloseLocationCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addLocationSubmitHandler = this.addLocationSubmitHandler.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatepwdhandleChangeInput =
      this.updatepwdhandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatePwdhandleClose = this.updatePwdhandleClose.bind(this);
    this.viewhandleClose = this.viewhandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.updatePwdhandleOpenCreateModal =
      this.updatePwdhandleOpenCreateModal.bind(this);
    this.handleOpenViewModal = this.handleOpenViewModal.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.handleChangeLocationInput = this.handleChangeLocationInput.bind(this);
    this.state = {
      viewRowData: {},
      placeDetails: {},
      markers: [],
      places: [],
      apiPlaces: [],
      selectedPlace: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      sortBy: "company_name",
      sortOrder: "1",
      landlordId: "",
      landlordCreateModal: false,
      locationCreateModal: false,
      updatelandlordCreateModal: false,
      updatePasswordCreateModal: false,
      viewLandlordModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        company_name: "",
        contact_name: "",
        address: "",
        contact: "",
        roll_id: "",
      },
      locationFormData: {
        line1: "",
        line2: "",
        line3: "",
        city: "",
        pin: "",
        country: "",
        latitude: "0.0",
        longitude: "0.0",
        region: "",
      },
      updateformData: {
        id: "",
        company_name: "",
        contact_name: "",
        address: "",
        contact: "",
        roll_id: "",
      },
      updatePwdformData: {
        id: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 0,
      size: this.state.size,
      sortOrder: "1",
      sortBy: "company_name",
    };
    this.props.dispatch(landlordActions.getLandlordList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.landlords.addLandlordSuccess) {
      return {
        ...nextProps,
        formData: {
          company_name: "",
          contact_name: "",
          email: "",
          password: "",
          address: "",
          mobile: "",
          roll_id: "",
        },
        landlordCreateModal: false,
        updatelandlordCreateModal: false,
        updatePasswordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      keyWord: this.state.keyWord,
      pageNo: page,
      roll_id: 0,
      size: this.state.size,
    };
    this.props.dispatch(landlordActions.getLandlordList(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      roll_id: 0,
      size: this.state.size,
    };
    this.props.dispatch(landlordActions.getLandlordList(data));
  }

  handleSort(columnName) {
    let sortOrder = this.state.sortOrder;
    sortOrder = sortOrder === "-1" ? "1" : sortOrder === "1" ? "" : "-1";

    this.setState({ page: 1, sortOrder, sortBy: columnName });
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 0,
      size: this.state.size,
    };
    if (sortOrder === "-1" || sortOrder === "1") {
      data["sortOrder"] = sortOrder;
      data["sortBy"] = columnName;
    }

    this.props.dispatch(landlordActions.getLandlordList(data));
  }

  handleOpenCreateModal() {
    this.setState({ landlordCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatelandlordCreateModal: true, updateformData: data });
  }
  updatePwdhandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatePasswordCreateModal: true, updatePwdformData: data });
  }
  handleOpenViewModal(data) {
    // console.log("datadatadatadata", data);
    this.setState({ viewLandlordModal: true, viewRowData: data });
  }
  handleClose() {
    this.setState({ landlordCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatelandlordCreateModal: false });
  }
  updatePwdhandleClose() {
    this.setState({ updatePasswordCreateModal: false });
  }
  viewhandleClose() {
    this.setState({ viewLandlordModal: false });
  }

  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };

  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleChangeInputPlaceDetails = (event) => {
    const { placeDetails } = this.state;
    placeDetails[event.target.name] = event.target.value;
    this.setState({ placeDetails });
  };

  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };

  updatepwdhandleChangeInput = (event) => {
    const { updatePwdformData } = this.state;
    updatePwdformData[event.target.name] = event.target.value;
    this.setState({ updatePwdformData });
  };

  handleSubmit = () => {
    let reqData = {
      company_name: this.state.formData.company_name,
      contact_name: this.state.formData.contact_name,
      email: this.state.formData.email,
      password: this.state.formData.password,
      address: this.state.formData.address,
      contact: this.state.formData.mobile,
      roll_id: 0,
    };
    this.props.dispatch(landlordActions.addLandlord(reqData));
  };

  getCoordinates = () => {

    const address = `${this.state.locationFormData.find} `;
    const params = {
      access_key: "AIzaSyAnVTG8T_xBUr283Hi8ZqZevwZ6IC1Zg_g",
      query: address,
    };
    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${params.query}&key=${params.access_key}&sensor=false`)
      .then((response) => {
        console.log('response.data.data____________99999999999999999', response);
        const { locationFormData } = this.state;
        if (response.data.data) {

          const { latitude, longitude, country, region } =
            response.data.data[0];
          locationFormData["latitude"] = latitude;
          locationFormData["longitude"] = longitude;
          locationFormData["country"] = country;
          locationFormData["region"] = region;
          this.setState({
            locationFormData, apiPlaces: response.data.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeLocationInput = (event) => {
    const { name, value } = event.target;
    const { locationFormData } = this.state;
    locationFormData[name] = value;
    this.setState({ locationFormData });

    console.log("Form DAta", this.state.locationFormData);
  };

  handleCoordinate = (element) => {
    console.log('elementelementelementelement', element);
    const { locationFormData } = this.state;
    locationFormData["line3"] = element.name;
    locationFormData["latitude"] = element.latitude;
    locationFormData["longitude"] = element.longitude;
    locationFormData["country"] = element.country;
    locationFormData["region"] = element.region;
    let { address_components, geometry } = element;
    for (let index = 0; index < address_components.length; index++) {
      const element = address_components[index];
      if (element.types.includes("postal_code")) {
        locationFormData["pin"] = element.long_name;
      }
      if (element.types.includes("country")) {
        locationFormData["country"] = element.long_name;
      }
      if (element.types.includes("administrative_area_level_1")) {
        locationFormData["region"] = element.long_name;
      }
    }
    if (address_components && address_components[0]) {
      locationFormData["line1"] = address_components[0].long_name;
    }
    if (geometry && geometry.location) {
      locationFormData["latitude"] = geometry.location.lat;
      locationFormData["longitude"] = geometry.location.lng;
    }
    this.setState({ locationFormData, apiPlaces: [] });

  };



  locationHandler = (event, val) => {
    if (!event.target.value) {
      this.setState({
        apiPlaces: []
      });
    }
    const params = {
      access_key: "AIzaSyAnVTG8T_xBUr283Hi8ZqZevwZ6IC1Zg_g",
      query: event.target.value,
    };
    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${params.query}&key=${params.access_key}`)
      .then((response) => {
        const { locationFormData } = this.state;
        if (response.data.results) {
          this.setState({
            locationFormData, apiPlaces: response.data.results
          });
        } else {
          this.setState({
            apiPlaces: []
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // if (val.option) {
    //   locationFormData.products.push(val.option.id);
    //   this.setState({ locationFormData });
    // }
    // else if (val.removedValue) {
    //   locationFormData.products.forEach((deleteId, delIndex) => {
    //     if (deleteId === val.removedValue.id) {
    //       locationFormData.products.splice(delIndex, 1);
    //     }
    //   })
    //   this.setState({ locationFormData });
    // }

  }

  addLocationSubmitHandler = () => {
    let reqData = {
      line1: this.state.locationFormData.line1,
      line2: this.state.locationFormData.line2,
      line3: this.state.locationFormData.line3,
      city: this.state.locationFormData.city,
      pin: this.state.locationFormData.pin.trim(),
      state: this.state.locationFormData.region,
      country: this.state.locationFormData.country,
      coordinates: [
        this.state.locationFormData.latitude,
        this.state.locationFormData.longitude,
      ],
      created_by: this.state.landlordId,
    };
    this.props.dispatch(locationLandlordActions.addLocationLandlord(reqData));
    const locationFormData = {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      pin: "",
      country: "",
      latitude: "0.0",
      longitude: "0.0",
      region: "",
    };
    this.setState({ locationCreateModal: false, locationFormData });
  };
  handleOpenLocationCreateModal(id) {
    console.log("I am working.......");
    this.setState({ locationCreateModal: true, landlordId: id });
  }
  handleCloseLocationCreateModal() {
    const locationFormData = {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      pin: "",
      country: "",
      latitude: "0.0",
      longitude: "0.0",
      region: "",
    };
    this.setState({ locationCreateModal: false, locationFormData });
  }
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      company_name: this.state.updateformData.company_name,
      contact_name: this.state.updateformData.contact_name,
      address: this.state.updateformData.address,
      contact: this.state.updateformData.contact,
      // "roll_id": 0
    };
    console.log("reqDatareqDatareqDatareqData  ", reqData);

    this.props.dispatch(landlordActions.updateLandlord(reqData));
  };
  updatepwdhandleSubmit = () => {
    let reqData = {
      id: this.state.updatePwdformData.id,
      password: this.state.updatePwdformData.password,
    };
    console.log("reqDatareqaupdatepwdhandleSubmit  ", reqData);

    this.props.dispatch(landlordActions.updatePassword(reqData));
  };

  onChangeFile(event) {
    this.props.dispatch(
      landlordActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      roll_id: 0,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(landlordActions.deleteLandlord(tempdata));
  }
  onDisable(data) {
    let tempdata = {
      resourceId: data.id,
      resourceType: "user",
      value: !data.active,
    };
    this.props.dispatch(landlordActions.disableLandlord(tempdata));
  }
  render() {
    let { landlords, classes } = this.props;
    let { landlordListItems, total } = landlords;

    // console.log("items__________RENDER:::", landlordListItems);
    // console.log("items__________RENDER:::", landlordListItems);
    console.log("RENDER__________apiPlaces:::", this.state.apiPlaces);

    return (
      <>
        <PageTitle title="Landlords" />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container>
                <Grid item sm />
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search email"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    // className={classes.textField}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    // className={classes.button}
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              {landlordListItems && landlordListItems.length > 0 ? (
                <>
                  <Table
                    sortOrder={this.state.sortOrder}
                    sortBy={this.state.sortBy}
                    data={landlordListItems}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    updatePwdhandleOpenCreateModal={
                      this.updatePwdhandleOpenCreateModal
                    }
                    handleOpenViewModal={this.handleOpenViewModal}
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                    handleSort={this.handleSort}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddLandlordDialog
          landlordCreateModal={this.state.landlordCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        />
        <AddLocationLandlordDialog
          locationCreateModal={this.state.locationCreateModal}
          classes={classes}
          formData={this.state.locationFormData}
          apiPlaces={this.state.apiPlaces}
          selectedPlace={this.state.selectedPlace}
          placeDetails={this.state.placeDetails}
          handleChangeInputPlaceDetails={this.handleChangeInputPlaceDetails}
          handleCoordinate={this.handleCoordinate}
          handleSubmit={this.addLocationSubmitHandler}
          handleChangeInput={this.handleChangeLocationInput}
          locationHandler={this.locationHandler}
          handleClose={this.handleCloseLocationCreateModal}
        />
        <UpdateLandlordDialog
          updateuserCreateModal={this.state.updatelandlordCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatelocationHandler={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
        />


        <UpdatePasswordDialog
          updateuserCreateModal={this.state.updatePasswordCreateModal}
          classes={classes}
          updatePwdformData={this.state.updatePwdformData}
          updatepwdhandleSubmit={this.updatepwdhandleSubmit}
          updatepwdhandleChangeInput={this.updatepwdhandleChangeInput}
          updatePwdhandleClose={this.updatePwdhandleClose}
        />
        <ViewLandlordDialog
          landlordCreateModal={this.state.viewLandlordModal}
          locationCreateModal={this.state.landlordCreateModal}
          classes={classes}
          handleClose={this.viewhandleClose}
          viewRowData={this.state.viewRowData}
          handleOpenLocationCreateModal={this.handleOpenLocationCreateModal}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { landlords, users } = state;
  return {
    landlords,
    users,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(LandlordManagement)),
);
