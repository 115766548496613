import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const landlordService = {
  login,
  logout,
  addLandlord,
  updatePassword,
  uploadImage,
  getLandlordList,
  disableLandlord,
  updateLandlord,
  deleteLandlord,
  getAllLandlord,
  getLandlordDetails,
  getTimeData,
};
function logout() {
  localStorage.removeItem("landlord");
}
function login(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
    .then(handleResponse)
    .then((landlord) => {
      let landlordObj = {
        landlordinfo: landlord.data,
      };
      if (landlord.data) {
        localStorage.setItem("landlord", JSON.stringify(landlord.data));
      }

      return landlordObj;
    });
}
function updatePassword(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updatePassword`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let userObj = {
        updatePassword: data.data,
      };
      console.log("I am in service", userObj);

      return userObj;
    });
}
function getLandlordList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        getLandlordList: data.data,
      };
      console.log();

      return landlordObj;
    });
}

function getTimeData(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/inAndOutTimeByAdmin`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        getTimeData: data.data,
      };
      console.log();

      return landlordObj;
    });
}
function getLandlordDetails(data) {
  // const id = data.id;
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getLandlordDetails`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        getLandlordDetails: data.data,
      };
      console.log();

      return landlordObj;
    });
}
// function getLandlordDetails(data) {
//   const id = data.id;
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//   };
//   return fetch(CONST.BACKEND_URL + `/getLandlordDetails/${id}`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let landlordObj = {
//         getLandlordDetails: data.data,
//       };
//       console.log();

//       return landlordObj;
//     });
// }
function getAllLandlord() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
  };
  return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        getAllLandlord: data.data,
      };
      console.log();

      return landlordObj;
    });
}
function addLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/createNewUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        addLandlord: data.data,
      };
      console.log();

      return landlordObj;
    });
}
function updateLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        addLandlord: data.data,
      };
      return landlordObj;
    });
}
function disableLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        addLandlord: data.data,
      };
      return landlordObj;
    });
}
function deleteLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/deleteLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let landlordObj = {
        addLandlord: data.data,
      };
      console.log();

      return landlordObj;
    });
}
function uploadImage(filedata) {
  let header = new Headers({
    Authorization: authHeader().Authorization,
  });
  var data = new FormData();
  data.append("image", filedata);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: data,
  };
  return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      let landlordObj = {
        filesDetails: res.data,
      };
      return landlordObj;
    });
}
// function siteInfoFileUpload(filedata) {
//   let header = new Headers({
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: filedata,
//   };
//   return fetch(CONST.BACKEND_URL + `/upload/siteInfo`, requestOptions)
//     .then(handleResponse)
//     .then((res) => {
//       let landlordObj = {
//         filesDetails: res.data,
//       };
//       return landlordObj;
//     });
// }

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
