import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";
import ViewJobPdfDetails from "../ViewJobPdfDetails/ViewJobPdfDetails";
import floorPlan from "../../../../images/floor-plan.svg";
import roofPlan from "../../../../images/roof-plan.svg";
import Fire from "../../../../images/fire.svg";
import Electrical from "../../../../images/electrical.svg";
import Sprinkler from "../../../../images/sprinkler.svg";
import Boiler from "../../../../images/boiler.svg";
import HVAC from "../../../../images/hvac.svg";
import Plumbing from "../../../../images/plumber-_1_ (1).svg";
import lift from "../../../../images/lift-elevator-icon (1).svg"

export default function ViewLocationLandlordDialog(props) {
  let {
    classes,
    jobCreateModal,
    siteInfoData,
    handleClose,
    viewRowData,
  } = props;
  console.log("siteInfoData in component  ", siteInfoData);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentName, setViewDocumentName] = useState("");
  const [viewDocumentLink, setViewDocumentLink] = useState([]);
  const handleClosePdf = () => {
    setViewDocument(false);
  };

  return (
    <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Location Details
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "0 30px",
          }}
        >
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              <u>Location Details</u>
            </Typography>

            <div className={classes.paper}>
              <Typography
                style={{ fontSize: "17px" }}
                component="h1"
                variant="h5"
              >
                <b>Address :</b>
                {viewRowData.line1}
                {viewRowData.line2}
                {viewRowData.line3}

                <br />
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>City :</b> {viewRowData.city}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Postal/Zip Code :</b> {viewRowData.pin}
                </Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Province/State :</b> {viewRowData.state}
                </Box>
              </Typography>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#f6f7f8",
              minHeight: "100vh",
              padding: "100px 30px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.floorPlansFileLinks ?
                    siteInfoData.floorPlansFileLinks : []);
                  setViewDocumentName(siteInfoData.floorPlans)
                }}
              >
                <img src={floorPlan} width="183px" height="" alt=" " />
              </button>
              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.roofPlansFileLinks ?
                    siteInfoData.roofPlansFileLinks : []);

                  setViewDocumentName(siteInfoData.roofPlans)
                }}
              >
                <img src={roofPlan} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.fireCertificationFileLinks ?
                    siteInfoData.fireCertificationFileLinks : []);
                  setViewDocumentName(siteInfoData.fireCertification)
                }}
              >
                <img src={Fire} width="183px" alt=" " />
              </button>
            </div>
            <div>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.electricalRoomFileLinks ?
                    siteInfoData.electricalRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.electricalRoom)
                }}
              >
                <img src={Electrical} width="183px" height="" alt=" " />
              </button>

              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.sprinklerRoomFileLinks ?
                    siteInfoData.sprinklerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.sprinklerRoom)
                }}
              >
                <img src={Sprinkler} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.boilerRoomFileLinks ?
                    siteInfoData.boilerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.boilerRoom)
                }}
              >
                <img src={Boiler} width="183px" alt=" " />
              </button>
            </div>
            <div>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.hvacEquipmentFileLinks ?
                    siteInfoData.hvacEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.hvacEquipment)
                }}
              >
                <img src={HVAC} width="183px" height="" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.plumbingEquipmentFileLinks ?
                    siteInfoData.plumbingEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.plumbingEquipment)
                }}
              >
                <img src={Plumbing} width="183px" height="" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.liftEquipmentFileLinks ?
                    siteInfoData.liftEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.liftEquipment)
                }}
              >
                <img src={lift} width="183px" height="" alt=" " />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Container> */}
      <ViewJobPdfDetails
        show={viewDocument}
        viewDocumentName={viewDocumentName}
        viewDocumentLink={viewDocumentLink}

        handleClosePdf={handleClosePdf} />
    </Dialog>
  );
}
