import React, { Component } from 'react';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import AddWorkerDialog from "./components/AddWorkerDialog/AddWorkerDialog";
import UpdateWorkerDialog from "./components/UpdateWorkerDialog/UpdateWorkerDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import { confirmAlert } from 'react-confirm-alert';
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { packageActions } from '../../_actions';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import styles from './styles';

class userPackage extends Component {

  constructor(props) {
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatepwdhandleChangeInput = this.updatepwdhandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatePwdhandleClose = this.updatePwdhandleClose.bind(this);
    this.updatehandleOpenCreateModal = this.updatehandleOpenCreateModal.bind(this);
    this.updatePwdhandleOpenCreateModal = this.updatePwdhandleOpenCreateModal.bind(this);
    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: '',
      sortBy: '',
      sortOrder: '',
      workerCreateModal: false,
      updateworkerCreateModal: false,
      updatePasswordCreateModal: false,
      updateitem: false,
      submitted: false,
      keyWord: '',
      Approveconditions: false,
      formData: {
        "first_name": "",
        "last_name": "",
        "email": "",
        "password": "",
        "address": "",
        "contact": "",
        "roll_id": ""
      },
      updateformData: {
        "id": "",
        "packName": "",
        "desc": "",
        "packNo": "",
      },
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getISPackageList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.packages.updateitem) {
      return {
        ...nextProps,
        workerCreateModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getISPackageList(data));
  }
  handleSearch(event) {
    this.handleSort = this.handleSort.bind(this);

    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getISPackageList(data));
  }
  handleOpenCreateModal() {
    this.setState({ workerCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updateworkerCreateModal: true, updateformData: data });
  }
  updatePwdhandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatePasswordCreateModal: true, updatePwdformData: data });
  }

  handleClose() {
    this.setState({ workerCreateModal: false });
  }
  updatehandleClose() {
    this.handleSort = this.handleSort.bind(this);

    this.setState({ updateworkerCreateModal: false });

  }
  updatePwdhandleClose() {
    this.setState({ updatePasswordCreateModal: false });

  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }
  updatepwdhandleChangeInput = (event) => {
    console.log("eventeventeventevent", event.target.value);
    const { updatePwdformData } = this.state;
    updatePwdformData[event.target.name] = event.target.value;
    this.setState({ updatePwdformData });
  }

  handleSort(event) {
    let sortOrder = this.state.sortOrder;
    sortOrder = sortOrder === "-1" ? "1" : (sortOrder === "1" ? "" : "-1");

    this.setState({ page: 1, sortOrder, sortBy: event });
    let data = {
      "keyWord": "",

      // "sortOrder": sortOrder,
      "pageNo": 1,
      "size": this.state.size
    }
    if (sortOrder === "-1" || sortOrder === "1") {
      data['sortOrder'] = sortOrder;
      data['sortBy'] = event;
      // "sortBy": event,
    }

    this.props.dispatch(packageActions.getWorkerList(data));
  }

  handleSubmit = () => {

    let reqData = {
      "packName": this.state.formData.packName,
      "min": parseInt(this.state.formData.min),
      "max": parseInt(this.state.formData.max),
      "desc": this.state.formData.desc,
    }
    this.props.dispatch(packageActions.createPackage(reqData));
  }
  updatehandleSubmit = () => {
    let reqData = {
      "id": this.state.updateformData.id,
      "packName": this.state.updateformData.packName,
      "min": this.state.updateformData.min,
      "max": this.state.updateformData.max,
      "desc": this.state.updateformData.desc,
    }
    console.log("reqData  ", reqData);

    this.props.dispatch(packageActions.updatePackage(reqData));
  }

  updatepwdhandleSubmit = () => {

    let reqData = {
      "id": this.state.updatePwdformData.id,
      "password": this.state.updatePwdformData.password,

    }
    console.log("reqDatareqaupdatepwdhandleSubmit  ", reqData);

    this.props.dispatch(packageActions.updatePassword(reqData));
  }

  onChangeFile(event) {
    this.props.dispatch(packageActions.uploadImage(event.target.files[event.target.files.length - 1]));
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`)
  }

  onDeleteClick = (data) => {
    console.log('datadatadatadatadata', data);
    let tempdata = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to delete Package?',
      message: `Are you sure to delete ${data.packageId.packName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.deleteUserPackage(tempdata, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  onApprove(data) {
    ;
    let tempdata = {
      "id": data.id,
    }
    let pagination = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    //console.log("asdf :: ",tempdata);
    confirmAlert({
      title: 'Confirm to approve Package?',
      message: `Are you sure to approve ${data.packageId.packName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.approveISPackage(tempdata, pagination))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable(data) {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    //console.log("asdf :: ",tempdata);
    confirmAlert({
      title: 'Confirm to disable Package?',
      message: `Are you sure to disable ${data.packageId.packName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.disableUserPackage(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
    // this.props.dispatch(packageActions.disablePackage(tempdata));
  }
  render() {

    let { packages, classes } = this.props;
    let { isPackItems } = packages;
    // console.log("itemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitemsitems=-=======", isPackTotal)

    return (
      <>
        <PageTitle title="User Package" />
        <Grid container >
          <Grid item xs={12} md={12} >
            <Widget >

              {
                isPackItems && isPackItems.length > 0 ?
                  <>
                    <Table data={isPackItems}
                      sortOrder={this.state.sortOrder}
                      sortBy={this.state.sortBy}
                      offset={this.state.offset}
                      onRowClick={this.onRowClick}
                      handleSort={this.handleSort}
                      updatehandleOpenCreateModal={this.updatehandleOpenCreateModal}
                      updatePwdhandleOpenCreateModal={this.updatePwdhandleOpenCreateModal}
                      onDeleteClick={this.onDeleteClick}
                      onDisable={this.onDisable}
                      onApprove={this.onApprove}
                    />

                  </>
                  : <Typography >Data not found.</Typography>
              }
            </Widget>
          </Grid>
        </Grid>
        <AddWorkerDialog
          workerCreateModal={this.state.workerCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        />
        <UpdateWorkerDialog
          updateuserCreateModal={this.state.updateworkerCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
        />
        <UpdatePasswordDialog
          updateuserCreateModal={this.state.updatePasswordCreateModal}
          classes={classes}
          updatePwdformData={this.state.updatePwdformData}
          updatepwdhandleSubmit={this.updatepwdhandleSubmit}
          updatepwdhandleChangeInput={this.updatepwdhandleChangeInput}
          updatePwdhandleClose={this.updatePwdhandleClose}
        />
      </>
    );

  }
}
function mapStateToProps(state) {
  const { packages } = state;
  console.log("usersusersusersusersusersmapStateToProps", packages);
  return {
    packages
  };
}

export default connect(mapStateToProps)(withStyles(styles)(withRouter(userPackage)));
